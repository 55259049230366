import React, { useEffect, useState } from 'react'
import { HashLink } from 'react-router-hash-link'
import '../../style/navbar.css'
import axios from 'axios'
import { APIpath } from '../../App'

export default function Header() {
    const [scrolled, setScrolled] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY
            if (offset > 250) {
                setScrolled(true)
            } else {
                setScrolled(false)
            }
        }
        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    // API WORK
    const [data, setData] = useState([])
    useEffect(() => {
        axios
            .get(APIpath.API + '/api/link-and-data')
            .then((res) => {
                setData(res?.data?.data?.attributes)
            })
            .catch((err) => console.log(err))
    }, [])

  return (
    <div className={`fixed top-0 customnav navbar z-[100] ${scrolled ? "navbar-scrolled" : ""}`}>
      <div className='hidden lg:flex navbar'>
        <div className='flex flex-row w-56 h-14 justify-center mx-16'>
          <a href={`mailto:${data?.Mail}`} ><svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" height="1.8rem" width="1.8rem"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" /><path d="M22 6l-10 7L2 6" /></svg></a>
          <a href={`tel:${data?.Telr}`} className='ml-4'><svg fill="currentColor" viewBox="0 0 16 16" height="1.6rem" width="1.6rem"><path d="M3.654 1.328a.678.678 0 00-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 004.168 6.608 17.569 17.569 0 006.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 00-.063-1.015l-2.307-1.794a.678.678 0 00-.58-.122l-2.19.547a1.745 1.745 0 01-1.657-.459L5.482 8.062a1.745 1.745 0 01-.46-1.657l.548-2.19a.678.678 0 00-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 012.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 00.178.643l2.457 2.457a.678.678 0 00.644.178l2.189-.547a1.745 1.745 0 011.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 01-7.01-4.42 18.634 18.634 0 01-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" /></svg></a>
          <a href={data?.Facebook} className='ml-1'><svg viewBox="0 0 24 24" fill="currentColor" height="2.3rem" width="2.3rem"><path d="M13 10h3v3h-3v7h-3v-7H7v-3h3V8.745c0-1.189.374-2.691 1.118-3.512C11.862 4.41 12.791 4 13.904 4H16v3h-2.1c-.498 0-.9.402-.9.899V10z" /></svg></a>
          <a href={data?.Instagram} className='ml-2'><svg fill="currentColor" viewBox="0 0 16 16" height="1.8rem" width="1.8rem"><path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 00-1.417.923A3.927 3.927 0 00.42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 001.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 00-.923-1.417A3.911 3.911 0 0013.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 01-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 01-.92-.598 2.48 2.48 0 01-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 100 1.92.96.96 0 000-1.92zm-4.27 1.122a4.109 4.109 0 100 8.217 4.109 4.109 0 000-8.217zm0 1.441a2.667 2.667 0 110 5.334 2.667 2.667 0 010-5.334z" /></svg></a>
        </div>
        <div className='flex flex-row'>
          <HashLink smooth={true} to="/#start" className='h-full text-md uppercase px-10'>Start</HashLink>
          <HashLink smooth={true} to="/#speisen" className='h-full text-md uppercase px-10'>Speisen</HashLink>
          <HashLink smooth={true} to="/#überuns" className='h-full text-md uppercase px-10'>Über Uns</HashLink>
          <HashLink smooth={true} to="/#contact" className='h-full text-md uppercase px-10'>Kontakt</HashLink>
          <HashLink smooth={true} to="/#news" className='h-full text-md uppercase px-10'>News</HashLink>
        </div>
      </div>
      <div className='flex navbar lg:hidden'>
        <div className="navbar-start">
          <div className="dropdown">
            <label tabIndex={0} className="btn btn-ghost btn-circle">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" /></svg>
            </label>
            <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 shadow navbar-scrolled w-56 rounded-box">
              <HashLink smooth={true} to="/#start" className='h-full text-md uppercase px-4 py-2'>Start</HashLink>
              <HashLink smooth={true} to="/#speisen" className='h-full text-md uppercase px-4 py-2'>Speisen</HashLink>
              <HashLink smooth={true} to="/#überuns" className='h-full text-md uppercase px-4 py-2'>Über Uns</HashLink>
              <HashLink smooth={true} to="/#contact" className='h-full text-md uppercase px-4 py-2'>Kontakt</HashLink>
              <HashLink smooth={true} to="/#news" className='h-full text-md uppercase px-4 py-2'>News</HashLink>
            </ul>
          </div>
        </div>
        <div className='navbar-end'>
          <h3 className='font-headline text-3xl'>{data.mobile_header}</h3>
        </div>
      </div>
    </div>
  )
}
