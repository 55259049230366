import React, { useEffect, useState } from "react";
import axios from "axios";
import { APIpath } from "../../App";
import "../../style/custom.css";

export default function InstagramSec() {
  // API WORK
  const [instaImg, setInstaImg] = useState([]);
  const [instaText, setInstaText] = useState([]);
  const [instaUrl, setInstaUrl] = useState("https://www.instagram.com/");

  useEffect(() => {
    axios
      .get(APIpath.API + "/api/instagram/images")
      .then((res) => setInstaImg(getLastThreeSortedPosts(res.data.results)))
      .catch((err) => console.log(err));
    axios
      .get(APIpath.API + "/api/link-and-data")
      .then((res) =>
        setInstaUrl(
          res?.data?.data?.attributes?.Instagram ?? "https://www.instagram.com/"
        )
      )
      .catch((err) => console.log(err));
    axios.get(APIpath.API + "/api/instagram-text")
      .then((res) => setInstaText(res?.data?.data?.attributes))
      .catch((err) => console.log(err))
  }, []);

  const getLastThreeSortedPosts = (posts) => {
    if (!posts) return [];
    posts.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    return posts.slice(0, 3);
  };

  return (
    <section
      id="news"
      className={"w-full flex flex-col mb-4 max-w-screen-2xl relative pt-16 px-4 md:px-16"}
    >
      <p className="absolute uppercase scale-90 md:scale-100 text-8xl font-headline text-shadowbg text-center right-[-50rem] -top-[8rem] -rotate-25 z-10">
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <br />
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <span className="pl-8 text-newyellow">News.</span>
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <br />
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <br />
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <br />
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <br />
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <br />
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <br />
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <span className="pl-8">News.</span>
        <br />
      </p>
      <div className="flex flex-col md:flex-row items-center justify-center w-full z-20 pt-16">
        {instaImg.map((item) => {
          return (
            <div key={item?.id} className="relative grow w-[80%] mb-8 md:mb-0 md:w-[30%] mx-4 hover:cursor-pointer ">
              <a href={instaUrl} target="_blank" rel="noreferrer">
                <img
                    loading="lazy"
                  src={item.originalUrl}
                  alt="Eine der letzten Instagram Posts von den Beefboten"
                  className="w-full h-full drop-shadow-sm"
                />
              </a>
              <a href={instaUrl} target="_blank" rel="noreferrer">
                <div className="absolute inset-0 flex items-center justify-center bg-black opacity-0 hover:opacity-50 transition-opacity"></div>
              </a>
            </div>
          );
        })}
      </div>
      <span className="w-full font-headline text-3xl z-20 text-black lg:pl-16 pt-8 text-center lg:text-start mb-16">
        {instaText?.text}
      </span>
    </section>
  );
}
