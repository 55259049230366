import InstagramSec from '../../components/instagram/InstagramSec';
import Landing from '../../components/Landing/Landing';
import Section1 from '../../components/Section1/Section1';
import Slide from '../../components/Section2/Slide';
import Section3 from '../../components/Section3/Section3';
import Section4 from '../../components/Section4/Section4';
import Section5 from '../../components/Section5/Section5';
import Section6 from '../../components/Section6/Section6';
import Contact from '../../components/Contact/Contact';
import ImgSec from '../../components/ImgSec/ImgSec';

export default function Homepage({ imgs }) {

  

  return (
    <main className={`flex flex-col justify-center items-center bg-white w-full `}>
      <Landing imgs={imgs} />
      <Section1 sec1={imgs.sec1}/>
      <Slide logos={imgs.sec2}/>
      <Section3 gallery={imgs.gallery}/>
      <ImgSec />
      <Section4 />
      <Section5 bgimg={imgs.testemonials} es={imgs.testemonialsTexts}/>
      <Section6 />
      <Contact />
      <InstagramSec />
    </main>
  )
}
