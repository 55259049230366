
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { APIpath } from '../../App'
import { Fade } from 'react-reveal'

export default function Section3({gallery}) {
    // API WORK
    // const [data, setData] = useState([])
    // useEffect(() => {
    //     axios.get(APIpath.API + '/api/gallerie?populate=*')
    //     .then(res => {
    //         console.log(res?.data?.data?.attributes?.Pics)
    //         setData(res?.data?.data?.attributes)})
    //     .catch(err => console.log(err))
    // }, [])


    let pic1 = APIpath.API +gallery?.Pics?.data[0]?.attributes?.url;
    let pic2 = APIpath.API +gallery?.Pics?.data[1]?.attributes?.url;
    let pic3 = APIpath.API +gallery?.Pics?.data[2]?.attributes?.url;
    let pic4 = APIpath.API +gallery?.Pics?.data[3]?.attributes?.url;
    let pic5 = APIpath.API +gallery?.Pics?.data[4]?.attributes?.url;
    let pic6 = APIpath.API +gallery?.Pics?.data[5]?.attributes?.url;
    let pic7 = APIpath.API +gallery?.Pics?.data[0]?.attributes?.url;
    let pic8 = APIpath.API +gallery?.Pics?.data[1]?.attributes?.url;
    let pic9 = APIpath.API +gallery?.Pics?.data[2]?.attributes?.url;
    let pic10 = APIpath.API +gallery?.Pics?.data[3]?.attributes?.url;
    let pic11 = APIpath.API +gallery?.Pics?.data[4]?.attributes?.url;
    let pic12 = APIpath.API +gallery?.Pics?.data[5]?.attributes?.url;

        
    return (
        <section id='überuns' className='flex flex-col w-full lg:px-32 items-center pt-16 md:pt-24 pb-12 xl:py-24'>
            <div className='flex flex-col lg:flex-row max-w-screen-2xl w-full px-8'>
                <h3 className='text-6xl sm:text-8xl text-newyellow lg:w-96 font-headline'>{gallery.Title}</h3>
                <div className='flex flex-col w-full lg:w-4/5 '>
                    <p className='text-lg text-black'>{gallery.Content}</p>
                    <h4 className='text-xl sm:text-2xl text-newyellow self-start md:self-end pr-32 font-headline flex'>P.S. Deine Beefboten</h4>
                </div>
            </div>
            <div className='inline-grid gap-4 md:grid-cols-2 xl:grid-cols-3 pt-16'>
            <Fade bottom cascade>
                <label htmlFor='imgModel1' className='w-96 md:w-[22rem] lg:grow h-80 bg-cover cursor-pointer' style={{backgroundImage: `url(${pic1})`}} />
                <label htmlFor='imgModel2' className='w-96 md:w-[22rem] lg:grow h-80 bg-cover cursor-pointer' style={{backgroundImage: `url(${pic2})`}} />
                <label htmlFor='imgModel3' className='w-96 md:w-[22rem] lg:grow h-80 bg-cover cursor-pointer' style={{backgroundImage: `url(${pic3})`}} />
            </Fade>
            <Fade bottom cascade>
                <label htmlFor='imgModel4' className='w-96 md:w-[22rem] lg:grow h-80 bg-cover cursor-pointer' style={{backgroundImage: `url(${pic4})`}} />
                <label htmlFor='imgModel5' className='w-96 md:w-[22rem] lg:grow h-80 bg-cover cursor-pointer' style={{backgroundImage: `url(${pic5})`}} />
                <label htmlFor='imgModel6' className='w-96 md:w-[22rem] lg:grow h-80 bg-cover cursor-pointer' style={{backgroundImage: `url(${pic6})`}} />
            </Fade>
            </div>

            {/* Model */}
            <Model model="imgModel1" pic={pic7}/>
            <Model model="imgModel2" pic={pic8}/>
            <Model model="imgModel3" pic={pic9}/>
            <Model model="imgModel4" pic={pic10}/>
            <Model model="imgModel5" pic={pic11}/>
            <Model model="imgModel6" pic={pic12}/>
        </section>
    )
}

function Model(props) {
    return(
        <>
            <input type="checkbox" id={props.model} className="modal-toggle" />
            <label htmlFor={props.model} className="modal cursor-pointer">
                <label className="" htmlFor="">
                    <div className='h-80 sm:h-[28rem] md:h-[80vh] w-[95vw] md:w-[60vw] bg-cover bg-center' style={{ backgroundImage: `url(${props.pic})` }} />
                </label>
            </label>
        </>
    
    )
}