import axios from 'axios'
import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { APIpath } from '../../App'

export default function Slide({logos}) {
    const [currentCheckBoxID, setCurrentCheckBoxID] = useState(1)

    // API WORK
    const [data, setData] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            await axios
                .get(APIpath.API + '/api/foods?populate=Icon')
                .then((res) => {
                    setData(res?.data?.data?.sort((a, b) => {
                        return a.attributes.Reihenfolge > b.attributes.Reihenfolge ? 1 : -1
                    }))
                    setCurrentCheckBoxID(res?.data?.data?.sort((a, b) => {
                        return a.attributes.Reihenfolge > b.attributes.Reihenfolge ? 1 : -1
                    })[0]?.id)
                })
                .catch((err) => console.log(err))
        }

        fetchData()
    }, [])



    return (
        <section id="speisen" className='w-full flex justify-center items-center bg-white pt-16 md:pt-24'>
            <div className='flex flex-row md:flex-col w-full justify-center bg-shadowbg'>
                <div className='flex flex-col md:flex-row justify-start md:justify-center bg-white'>
                    {data?.map((item, index) => {
                        let src = APIpath.API +  item?.attributes?.Icon?.data?.attributes?.url
                        return (
                            <div key={item.id} onClick={() => setCurrentCheckBoxID(item?.id)}  data-checked={currentCheckBoxID === item?.id}  className={`md:rounded-t-2xl h-16 w-20 md:h-20 md:w-24 flex justify-center items-center cursor-pointer hover:opacity-50 ${(currentCheckBoxID === item?.id) ? "bg-shadowbg" : "bg-white"}`}><img loading="lazy" src={src} alt='Food-Icon'/></div>
                        )
                    })
                    }
                </div>
                <div className='flex flex-col md:flex-row justify-center w-full pt-8 px-8 md:pt-16 md:px-20 relative'>
                    {data?.map((item, index) => {
                        return (
                            <Content key={item.id}  check={currentCheckBoxID === item?.id} title={data[index]?.attributes?.Title} abs1={data[index]?.attributes?.Absatz_1} abs2={data[index]?.attributes?.Absatz2}/>
                        )})
                    }

                </div>
            </div>
        </section>
    )
}

function Content(props) {
    const [pdfLink, setPdfLink] = useState('')
    useEffect(() => {
        axios
            .get(APIpath.API + '/api/speisekarte?populate=*')
            .then((res) => {
                setPdfLink(APIpath.API + res?.data?.data?.attributes?.speisekarte?.data?.attributes.url)
            })
            .catch((err) => console.log(err))
    }, [])

    const handleDownload = async () => {
        axios
            .get(
                pdfLink,
                {
                    responseType: 'blob',
                }
            )
            .then((res) => {
                const pdfUrl = URL.createObjectURL(res.data)
                const link = document.createElement('a')
                link.href = pdfUrl
                link.download = 'Speisekarte.pdf'
                link.click()
            })
            .catch((err) => console.log(err))

    }

    return (
        <div
            className={`h-full xl:h-64 flex-col pb-8 2xl:pb-12 w-full max-w-screen-2xl ${
                props.check ? 'flex' : 'hidden'
            }`}>
            <motion.div
                className="h-full"
                animate={{
                    opacity: props.check ? 1.0 : 0,
                    y: props.check ? 0 : 24,
                }}
                transition={{
                    duration: 0.5,
                }}>
                <h2 className="text-3xl sm:text-4xl font-headline text-newyellow pb-4 sm:pb-8">
                    {props.title}
                </h2>
                <p className="text-md sm:text-lg text-black">
                    <div dangerouslySetInnerHTML={{ __html: props.abs1 }} />
                </p>
                <p className="text-md sm:text-lg text-black">{props.abs2}</p>
                <button
                    onClick={handleDownload}
                    className="text-newyellow text-2xl text-right w-full font-headline z-50 py-2">
                    Download Speisekarte
                </button>
            </motion.div>
        </div>
    )
}
