import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { APIpath } from '../../App'

export default function Impressum() {
    // API WORK
    const [markdown, setData] = useState([''])

    useEffect(() => {
        axios
            .get(APIpath.API + '/api/impressum-datenschutz')
            .then((res) => {
                setData(res?.data?.data?.attributes?.Impressum.toString())
            })
            .catch((err) => console.log(err))
    }, [])

    return (
        <section className="w-full flex flex-col py-28 px-4 xl:p-40">
            <ReactMarkdown
                children={markdown}
                className="prose max-w-none"></ReactMarkdown>
        </section>
    )
}
