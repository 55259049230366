
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Fade } from 'react-reveal'

import GIF from '../../res/Burger.svg'
import { APIpath } from '../../App'

export default function Section6() {
     // API WORK
     const [data, setData] = useState([])
     useEffect(() => {
         axios.get(APIpath.API + '/api/sec-six')
         .then(res => setData(res.data.data.attributes))
         .catch(err => console.log(err))
     }, [])
  return (
    <section className='w-full max-w-screen-2xl h-fit flex flex-col md:flex-row py-8 md:pt-24 lg:pt-40 pr-12'>
        <div className='w-full md:w-96 grow hidden sm:flex items-center justify-center md:justify-end'>
          <img loading="lazy" src={GIF} alt="burgertruck" className='h-64 md:mr-16'/>
        </div>
        <Fade bottom cascade>
          <div className='flex flex-col w-full md:w-3/5 p-8 md:p-0 pr-4'>
              <h2 className='text-6xl md:text-8xl uppercase text-newyellow font-headline'>{data.Title}</h2>
              <h3 className='text-3xl sm:text-4xl text-newyellow pt-2 pb-4 md:pb-8 font-headline'>{data.SmallTitle}</h3>
              <p className='text-lg text-black'>{data.Absatz1}</p>
              <p className='text-lg text-black'>{data.Absatz2}</p>
          </div>
        </Fade>
    </section>
  )
}
