
import React from 'react'
import Impressum from '../../pages/common/Impressum'

export default function ImpressumRoute() {
  return (
    <main className='min-h-screen w-full flex flex-col'>
        <Impressum />
    </main>
  )
}
