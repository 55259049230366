import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { APIpath } from '../../App'
import { HashLink } from 'react-router-hash-link'
import { Fade } from 'react-reveal'

export default function Section1({sec1}) {
    // API WORK
    const [data, setData] = useState([])
    useEffect(() => {
        axios.get(APIpath.API + '/api/sec-one?populate=*')
        .then(res => {
          setData(res?.data?.data?.attributes)
        })
        .catch(err => console.log(err))
    }, [])

  return (
    <section className='flex flex-row w-full h-fit mt-16 md:mt-24'>
        <div  style={{backgroundImage: `url(${sec1})`}} className='w-2/5 h-[44rem] bg-center bg-cover hidden lg:flex'/>
        {/* <div className="h-[44rem] w-2/5 bg-orange-300"></div> */}
        <Fade right cascade>
          <div className="h-full w-full lg:w-3/5 flex flex-col pt-4 sm:pt-12 lg:pt-32 px-8 lg:pl-24 lg:pr-8 lg:max-w-3xl">
              <h2 className='text-6xl sm:text-8xl font-headline uppercase text-newyellow'>{data.Title}</h2>
              <h3 className='text-3xl sm:text-4xl font-headline text-newyellow max-w-xl pt-2 pb-8'>{data.SmallTitle}</h3>
              <p className='text-lg sm:text-xl text-black'>{data.Absatz1} <br/><br/></p>
              <p className='text-lg sm:text-xl text-black'>{data.Absatz2} <br/><br/></p>
              <p className='text-lg sm:text-xl text-black'>{data.Absatz3} <br/><br/></p>
              <p className='text-lg sm:text-xl text-black'>{data.Absatz4} <span className='text-newyellow font-headline text-xl sm:text-2xl'><HashLink to={'#contact'} smooth={true}>Kontakt</HashLink></span></p>
              {/* <div className='w-full md:hidden flex justify-center pt-8'>
                <div className='rounded-full w-64 h-64 bg-cover bg-center' style={{backgroundImage: `url(${pic})`}} />
              </div> */}
          </div>
        </Fade>
    </section>
  )
}
