import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { APIpath } from '../../App'
import { Zoom } from 'react-reveal'

export default function Landing ({ imgs }) {
    // API WORK
    const [data, setData] = useState([])
    useEffect(() => {
        axios
            .get(APIpath.API + '/api/landing?populate=*')
            .then((res) => {
                setData(res?.data?.data?.attributes)
            })
            .catch((err) => console.log(err))
    }, [])


    return (
        <section
            id="start"
            className="flex items-center w-full h-[40rem] text-white relative bg-cover bg-center lg:bg-top"
            // style={{ backgroundImage: `url(${imgs.bg})` }} 
            >
            <img src={imgs.bg} alt='hintergrundbild' className='absolute w-full h-full object-top object-cover'/>
            <div className="bg-black h-full w-full absolute opacity-40 lg:opacity-20 z-10" />
            <Zoom cascade>
                <div className="flex flex-col w-full xl:w-3/5 p-6 sm:p-16 lg:pl-32 z-20">
                    <h1 className="text-7xl sm:text-9xl font-medium w-full lg:w-[40rem] uppercase font-headline tracking-wide">
                        {data.Title}
                    </h1>
                    <p className="pt-4 text-xl tracking-wide font-light">
                        {data.Satz1}
                    </p>
                    <p className="pt-4 text-xl tracking-wide font-light">
                        {data.Satz2}
                    </p>
                </div>
            </Zoom>
            <img
                loading="eager"
                fetchpriority="high"
                src={imgs.logo}
                alt="logo"
                className="absolute hidden lg:flex top-16 right-16 z-50 w-48"
            />
        </section>
    )
}
