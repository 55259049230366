import React from 'react'
import Datenschutz from '../../pages/common/Datenschutz'

export default function DatenschutzRoute() {
  return (
    <main className='min-h-screen w-full flex flex-col'>
        <Datenschutz/>
    </main>
  )
}
