import React from 'react'
import '../../style/loading.css'

export default function LoadingScreen() {
  return (
    <div className='w-screen h-screen flex justify-center items-center bg-white'>
        <div class="three-body">
            <div class="three-body__dot"></div>
            <div class="three-body__dot"></div>
            <div class="three-body__dot"></div>
        </div>
    </div>
  )
}
