
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'

import '@splidejs/react-splide/css';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { APIpath } from '../../App';


export default function Section5({bgimg, es}) {
    // API WORK
    // const [es, setData] = useState([])
    

    const splideRef = useRef(null);

  function handlePrevClick() {
    splideRef.current.splide.go('-1');
  }

  function handleNextClick() {
    splideRef.current.splide.go('+1');
  }

  const headingSmall = <h3 className='w-full lg:hidden uppercase text-black text-4xl font-medium pl-8 pt-12'>Was unsere Kunden sagen</h3>
  const headingBig = <h3 className='hidden lg:flex w-2/5 uppercase text-black lg:py-24 pl-28 text-4xl font-medium'>Was unsere <br/> Kunden <br/> sagen</h3>

      return (

        <section className='w-full flex justify-center items-center py-16 bg-cover' style={{backgroundImage: `url(${bgimg})`}}>
            <div className='h-fit lg:h-96 w-full max-w-screen-xl bg-white/75 flex flex-col lg:flex-row'>
                {headingBig}
                {headingSmall}
                <div className="w-full lg:w-3/5 h-fit lg:h-full flex p-8 flex-col relative  lg:pb-0">
                    <Splide
                        ref={splideRef}
                        loop={true}
                        options={{
                            arrows: false,
                            perPage: 1,
                            speed: 300,
                            autoplay: false,
                            easing: 'linear',
                            type: 'loop',
                            pagination: false,
                            perMove: 1,
                        }}
                        >
                            {es.map((item) => {

                                return(

                                <SplideSlide key={item?.id}>
                                    <div className="w-full h-fit lg:h-full flex flex-col items-start lg:justify-center lg:px-16">
                                        <h4 className='border-b border-black text-5xl text-black'>{item?.attributes?.NR}.</h4>
                                        <p className='text-black pt-4 font-headline text-lg md:text-xl'>{item?.attributes?.Content}</p>
                                    </div>
                                </SplideSlide>
                                )
                            })}


                    </Splide>

                    <div className='absolute top-8 lg:top-auto lg:bottom-3 xl:bottom-8 right-8 lg:right-24'>
                            <button aria-label="Next" onClick={handlePrevClick}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8 text-black"><path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" /></svg></button>
                            <button aria-label="Previous" onClick={handleNextClick}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8 text-black"><path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" /></svg></button>
                    </div>
                   </div>
            </div>
        </section>
    )
}
