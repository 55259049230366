
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { APIpath } from '../../App'
import { Fade } from 'react-reveal'

export default function Section4() {
    // API WORK
    const [data, setData] = useState([])
    useEffect(() => {
        axios.get(APIpath.API + '/api/sec-four')
        .then(res => setData(res?.data?.data?.attributes))
        .catch(err => console.log(err))
    }, [])

  return (
    <Fade bottom casacde>
      <section className='flex flex-col w-full max-w-screen-2xl px-8 pb-12 md:py-24'>
          <h2 className='text-newyellow text-6xl md:text-8xl font-headline font-medium'>{data.Title}</h2>
          <h3 className='text-newyellow md:pl-1 text-3xl sm:text-4xl font-headline pt-0 pb-4 md:pb-8'>{data.SmallTitle}</h3>
          <p className='text-black w-full sm:w-4/5 text-lg'>{data.Content}</p>
      </section>
    </Fade>
  )
}
