import React from 'react'
import { HashLink } from 'react-router-hash-link'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { APIpath } from '../../App'

export default function Footer() {
    // API WORK
    const [logo, setLogo] = useState([])
    const [data, setData] = useState([])
    useEffect(() => {
        axios
            .get(APIpath.API + '/api/logo?populate=*')
            .then((res) =>
                setLogo(
                    APIpath.API +
                        res?.data?.data?.attributes?.Picture?.data?.attributes
                            ?.url
                )
            )
            .catch((err) => console.log(err))

        axios
            .get(APIpath.API + '/api/link-and-data')
            .then((res) => setData(res?.data?.data?.attributes))
            .catch((err) => console.log(err))
    }, [])

    return (
        <footer className="relative py-12 px-16 bg-shadowbg h-fit  text-neutral-content flex flex-col md:flex-row z-20">
            <div className="h-full w-full md:w-1/2 flex flex-col items-center md:items-start text-black">
                <img
                    loading="lazy"
                    src={`${logo}`}
                    alt="logo"
                    className="scale-90 w-48"
                />
                <h3>Beefboten</h3>
                <p>Tel.: 0172 375 97 84</p>
                <p>Mail: info@beefboten.de</p>
            </div>
            <div className="grow w-full md:w-1/2 flex flex-col items-center md:items-end justify-between text-black">
                <div className="flex flex-row w-fit py-4">
                    <HashLink
                        smooth={true}
                        to="#start"
                        className="h-full text-md uppercase px-2 sm:px-4 hover:underline">
                        Start
                    </HashLink>
                    <HashLink
                        smooth={true}
                        to="#speisen"
                        className="h-full text-md uppercase px-2 sm:px-4 hover:underline">
                        Speisen
                    </HashLink>
                    <HashLink
                        smooth={true}
                        to="#überuns"
                        className="h-full text-md uppercase px-2 sm:px-4 hover:underline text-center">
                        Über Uns
                    </HashLink>
                    <HashLink
                        smooth={true}
                        to="#contact"
                        className="h-full text-md uppercase px-2 sm:px-4 hover:underline">
                        Kontakt
                    </HashLink>
                    <HashLink
                        smooth={true}
                        to="#news"
                        className="h-full text-md uppercase px-2 sm:px-4 hover:underline">
                        News
                    </HashLink>
                </div>
                <div className="flex flex-col">
                    <div className="flex flex-row w-full justify-center md:justify-end items-center h-full pb-2">
                        <a href={data?.Facebook} className="mr-2">
                            <svg
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                height="2.5rem"
                                width="2.5rem">
                                <path d="M13 10h3v3h-3v7h-3v-7H7v-3h3V8.745c0-1.189.374-2.691 1.118-3.512C11.862 4.41 12.791 4 13.904 4H16v3h-2.1c-.498 0-.9.402-.9.899V10z" />
                            </svg>
                        </a>
                        <a href={data?.Instagram} className="">
                            <svg
                                fill="currentColor"
                                viewBox="0 0 16 16"
                                height="1.9rem"
                                width="1.9rem">
                                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 00-1.417.923A3.927 3.927 0 00.42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 001.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 00-.923-1.417A3.911 3.911 0 0013.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 01-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 01-.92-.598 2.48 2.48 0 01-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 100 1.92.96.96 0 000-1.92zm-4.27 1.122a4.109 4.109 0 100 8.217 4.109 4.109 0 000-8.217zm0 1.441a2.667 2.667 0 110 5.334 2.667 2.667 0 010-5.334z" />
                            </svg>
                        </a>
                    </div>
                    <div className="flex flex-row w-fit">
                        <HashLink className="hover:underline" to={'/impressum'}>
                            Impressum
                        </HashLink>
                        <p className="mx-2">|</p>
                        <HashLink
                            className="hover:underline"
                            to={'/datenschutz'}>
                            Datenschutz
                        </HashLink>
                    </div>
                </div>
            </div>
        </footer>
    )
}
