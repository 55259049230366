import IMG from "../../res/callbeefbote.png"
import React from 'react'

export default function ImgSec() {
  return (
    <section className='h-fit w-full max-w-screen-2xl flex items-end justify-end py-4'>
        <img loading="lazy" src={IMG} alt="Call Beefbote" className="scale-90"/>
    </section>
  )
}
