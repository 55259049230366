import axios from 'axios'
import { APIpath } from '../../App'
import { useState } from 'react'

export default function Contact() {
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [msg, setMsg] = useState('')
    function handleSubmit(event) {
        event.preventDefault()
        setLoading(true)
        const data = {
            name: event.target.name.value,
            email: event.target.email.value,
            message: event.target.message.value,
            event_location: event.target.event_location.value,
            participants: event.target.participants.value,
            event_date: event.target.event_date.value,
        }
        // send data to mail api with header x-auth
        axios
            .post(APIpath.MAIL , data, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth': APIpath.MAIL_KEY,
                },
            })
            .then((res) => {
                if (res.data.code === 200) {
                    setSuccess(true)
                    setMsg(
                        'Die Nachricht wurde erfolgreich versendet. Wir melden uns!'
                    )
                    setLoading(false)
                } else {
                    setSuccess(false)
                    setMsg(
                        res.data?.DisplayMessage ??
                            'Die Nachricht konnte nicht versendet werden. Bitte versuche es über eine andere Kontaktmöglichkeit.'
                    )
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
                setMsg(
                    err?.response?.data?.DisplayMessage ??
                        'Die Nachricht konnte nicht versendet werden. Bitte versuche es über eine andere Kontaktmöglichkeit.'
                )
            })
    }

    return (
        <section id="contact" className="w-full max-w-screen-2xl pt-12 md:pt-24 xl:pt-32 ">
            <form
                autoComplete="on"
                className="w-full max-w-screen-2xl grid gap-6 md:gap-12 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 p-8 sm:px-16 md:px-24"
                onSubmit={handleSubmit}>
                <h3 className="text-6xl xl:text-7xl text-newyellow font-headline">
                    schreib uns
                </h3>
                <div className="flex items-end">
                    <input
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Name | Unternehmen"
                        className="input w-full bg-white border-slate-600 rounded-none text-sm"
                    />
                </div>
                <div className="flex items-end">
                    <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="E-Mail Adresse"
                        className="input w-full bg-white border-slate-600 rounded-none text-sm"
                    />
                </div>
                <input
                    type="text"
                    id="event_location"
                    name="event_location"
                    placeholder="Verstanstaltungsort (PLZ und Ort)"
                    className="input w-full bg-white border-slate-600 rounded-none text-sm"
                />
                <input
                    type="text"
                    id="participants"
                    name="participants"
                    placeholder="Personenanzahl (erwartet)"
                    className="input w-full bg-white border-slate-600 rounded-none text-sm"
                />
                <input
                    type="text"
                    id="event_date"
                    name="event_date"
                    placeholder="Eventtag (Datum und Uhrzeit)"
                    className="input w-full bg-white border-slate-600 rounded-none text-sm"
                />
                <textarea
                    type="text"
                    id="message"
                    name="message"
                    placeholder="Nachricht | Wünsche an deine Beefboten (je genauer desto besser)"
                    className="input w-full bg-white border-slate-600 rounded-none col-start-1 col-end-2 md:col-end-3 lg:col-end-4 h-48 pl-4 pt-2 text-sm"
                />
                <div className="flex flex-col gap-2">
                    <span
                        className={
                            success
                                ? 'text-newyellow text-center md:text-start'
                                : ''
                        }>
                        {loading ? '' : msg}
                    </span>
                    <button
                        className={`border border-newyellow bg-white z-50 text-newyellow font-headline w-48 text-3xl hover:bg-newyellow hover:text-white ${
                            loading ? 'cursor-not-allowed loading' : ''
                        } ${success ? 'hidden' : ''}`}
                        type="submit">
                        Senden
                    </button>
                </div>
            </form>
        </section>
    )
}
