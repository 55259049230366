import { Route, Routes } from 'react-router-dom'
import Header from './pages/common/Header'
import HomepageRoute from './components/routes/HomepageRoute'
import ImpressumRoute from './components/routes/ImpressumRoute'
import DatenschutzRoute from './components/routes/DatenschutzRoute'
import Footer from './pages/common/Footer'
import { useEffect, useState } from 'react'
import LoadingScreen from './components/Loading/LoadingScreen'
import axios from 'axios'
import ReactGA from 'react-ga4'
import { motion } from 'framer-motion'
import { Helmet } from 'react-helmet'
import { useQuery } from '@tanstack/react-query'
import { getSEO } from './api/apiCalls'

export const APIpath = {
    API: process.env.REACT_APP_CMS_URL ?? 'https://cms.beefboten.de',
    MAIL: process.env.REACT_APP_MAIL_URL ?? 'https://cds.wicode.io/contact',
    MAIL_KEY:
        process.env.REACT_APP_MAIL_KEY ??
        'ba41f05bac0d8b6203bdabd78137550b7d5b68fac390414c4259dc8eb80a86a8',
}

const GA_TRACKING_ID = 'G-D2C6TGB10T'

function App() {
    const { error, data, isLoading } = useQuery({
        queryKey: ['seo'],
        queryFn: getSEO,
    })

    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(true)
    const [bgpic, setBgpic] = useState([])
    const [logo, setLogo] = useState([])
    const [picSec1, setPicSec1] = useState([])
    const [foodLogos, setFoodLogos] = useState([])
    const [gallery, setGallery] = useState([])
    const [testemonials, setTestemonials] = useState([])
    const [testemonialsTexts, setTestemonialsTexts] = useState([])

    // listen on loading of front pic
    const handleLoading = () => {
        setLoading(false)
    }

    // API - CMS
    useEffect(() => {
        const loadImages = async () => {
            // get testemonial text
            await axios
                .get(APIpath.API + '/api/rezensions')
                .then((res) => setTestemonialsTexts(res?.data?.data))
                .catch((err) => console.log(err))

            // get bg image
            await axios
                .get(APIpath.API + '/api/landing?populate=*')
                .then((res) =>
                    setBgpic(
                        APIpath.API +
                            res?.data?.data?.attributes?.BackgroundImg?.data
                                ?.attributes?.url
                    )
                )
                .catch((err) => console.log(err))
            handleLoading()

            // get Logo
            await axios
                .get(APIpath.API + '/api/logo?populate=*')
                .then((res) =>
                    setLogo(
                        APIpath.API +
                            res?.data?.data?.attributes?.Picture?.data
                                ?.attributes?.url
                    )
                )
                .catch((err) => console.log(err))

            // section 1 pic
            await axios
                .get(APIpath.API + '/api/sec-one?populate=*')
                .then((res) =>
                    setPicSec1(
                        APIpath.API +
                            res?.data?.data?.attributes?.Picture?.data
                                ?.attributes?.url
                    )
                )
                .catch((err) => console.log(err))

            // section 2 food icons
            await axios
                .get(APIpath.API + '/api/icon-logo?populate=*')
                .then((res) => {
                    const response =
                        res?.data?.data?.attributes?.Iconlogo?.data.map(
                            (element) => APIpath.API + element?.attributes?.url
                        )
                    setFoodLogos(response)
                })
                .catch((err) => console.log(err))

            // Gallery
            await axios
                .get(APIpath.API + '/api/gallerie?populate=*')
                .then((res) => setGallery(res?.data?.data?.attributes))
                .catch((err) => console.log(err))

            // Testemonials Pic
            await axios
                .get(APIpath.API + '/api/rezension-pic?populate=*')
                .then((res) =>
                    setTestemonials(
                        APIpath.API +
                            res?.data?.data?.attributes?.BgPic?.data?.attributes
                                ?.url
                    )
                )
                .catch((err) => console.log(err))
        }

        loadImages()
    }, [])

    // Google Analytics
    useEffect(() => {
        if (localStorage.getItem('beefbote_homepage_cookie_storage') === null) {
            setVisible(true)
        } else if (localStorage.getItem('beefbote_homepage_cookie_storage')) {
            enableAnalytics()
        }
    }, [])

    const handleAcceptAll = () => {
        localStorage.setItem('beefbote_homepage_cookie_storage', true)
        setVisible(!visible)
        enableAnalytics()
    }

    const handleAcceptNecessary = () => {
        setVisible(!visible)
    }

    // enables Google Analytics
    const enableAnalytics = () => {
        console.log('executing ga')
        ReactGA.initialize(GA_TRACKING_ID)
    }

    return (
        <main className="flex flex-col font-textfam w-full overflow-hidden">
            {isLoading && <LoadingScreen />}
            {error && <LoadingScreen />}
            {!isLoading && !error && (
                <div className={`'block'}`}>
                    <Helmet>
                        <title>{data.metaTitle}</title>
                        <meta name="title" content={data.metaTitle} />
                        <meta name="og:title" content={data.metaTitle} />
                        <meta
                            name="description"
                            content={data.metaDescription}
                        />
                        <meta
                            name="og:description"
                            content={data.metaDescription}
                        />
                        <meta name="keywords" content={data.keywords} />
                        <meta name="robots" content={data.metaRobots} />
                        <link rel="canonical" href={data.canonicalURL} />
                        <meta
                            name="og:image"
                            content={
                                APIpath.API +
                                data.metaImage?.data?.attributes?.url
                            }
                        />
                    </Helmet>
                    <motion.div
                        initial={{
                            y: 500,
                        }}
                        animate={{
                            y: !visible ? 500 : 0,
                        }}
                        transition={{
                            duration: 0.2,
                            type: 'ease',
                        }}
                        className="fixed bottom-0 h-fit max-h-screen z-50 w-screen bg-black/90 flex flex-col p-8 sm:p-16">
                        <h4 className="text-3xl font-extrabold text-white underline">
                            Diese Website benutzt Cookies & Google Analytics!
                        </h4>
                        <p className="text-sm sm:text-base text-white py-2 leading-5">
                            Wir verwenden Cookies und andere Technologien auf
                            unserer Website. Einige von ihnen sind essenziell,
                            während andere uns helfen, diese Website und Ihre
                            Erfahrung zu verbessern. Personenbezogene Daten
                            können verarbeitet werden (z. B. IP-Adressen), z. B.
                            für personalisierte Anzeigen und Inhalte oder
                            Anzeigen- und Inhaltsmessung. Weitere Informationen
                            über die Verwendung Ihrer Daten finden Sie in
                            unserer Datenschutzerklärung. Sie können Ihre
                            Auswahl jederzeit unter Einstellungen widerrufen
                            oder anpassen.
                        </p>
                        <div className="flex flex-row justify-end w-full">
                            <button
                                onClick={handleAcceptNecessary}
                                className="text-gray-400 hover:text-gray-300 mr-8">
                                Nur notwendige zulassen
                            </button>
                            <button
                                onClick={handleAcceptAll}
                                className="border-newyellow text-newyellow hover:bg-newyellow h-14 px-4 border text-lg hover:text-white w-48 rounded-none">
                                Bestätigen
                            </button>
                        </div>
                    </motion.div>
                    <Header />
                    <Routes>
                        <Route
                            exact
                            path="/"
                            element={
                                <HomepageRoute
                                    imgs={{
                                        bg: bgpic,
                                        logo: logo,
                                        sec1: picSec1,
                                        sec2: foodLogos,
                                        gallery: gallery,
                                        testemonials: testemonials,
                                        testemonialsTexts: testemonialsTexts,
                                    }}
                                />
                            }
                        />
                        <Route
                            exact
                            path="/impressum"
                            element={<ImpressumRoute />}
                        />
                        <Route
                            exact
                            path="/datenschutz"
                            element={<DatenschutzRoute />}
                        />
                    </Routes>
                    <Footer />
                </div>
            )}
            {/* } */}
        </main>
    )
}

export default App
