
import Homepage from '../../pages/page_home/Homepage'

export default function HomepageRoute({ imgs }) {
    return (
        <main className="min-h-screen w-full flex flex-col">
            <Homepage imgs={imgs} />
        </main>
    )
}
